<script lang="ts" setup>
import { toast } from "vue3-toastify";

const { t } = useT();
const { isOpen } = useNlcModals();
const { initAndOpen } = useSupportChat();
const { isDesktop } = useDevice();

const toastOptions = {
	theme: toast.THEME.DARK,
	position: isDesktop ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.TOP_CENTER
};
const {
	unreadNotifications,
	readNotifications,
	isActiveAnimation,
	setNotificationsStatusAsRead,
	updateNotificationsStatusAsRead,
	handleClickNotificationMessage
} = useNotificationCenter({ isOpen, toastOptions });

const handleClickOpenSupport = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "widget",
		button_name: "support"
	});
	initAndOpen();
};

onMounted(() => {
	if (!unreadNotifications.value.length) {
		return;
	}
	const unreadOfferIds = unreadNotifications.value.reduce<number[]>((acc, item) => {
		if (item.type === "offer" && new Date(item.dateEnd).getTime() < new Date().getTime()) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadOfferIds.length) {
		setNotificationsStatusAsRead(unreadOfferIds);
	}
});
onUnmounted(() => {
	if (!unreadNotifications.value.length) {
		return;
	}
	const unreadIds = unreadNotifications.value.reduce<number[]>((acc, item) => {
		if (!item.link) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadIds.length) {
		updateNotificationsStatusAsRead(unreadIds);
	}
});
</script>

<template>
	<APopper placement="bottom" arrow offsetDistance="11">
		<div class="notification-center-icon" data-tid="notification-center">
			<NuxtIcon :class="{ bell: isActiveAnimation }" name="24/mail" filled />
			<ABadge v-if="unreadNotifications?.length" background="var(--changchun)" :size="14" class="counter">
				<AText class="text-cannes" variant="texas">{{ unreadNotifications.length }}</AText>
			</ABadge>
		</div>
		<template #content="{ close }">
			<div class="notification-center-popper">
				<header>
					<div>
						<AText class="text-cannes" variant="toledo" :modifiers="['bold']">{{ t("InBox") }}</AText>
						<AText v-if="unreadNotifications?.length" class="count" :modifiers="['medium']">{{
							unreadNotifications.length
						}}</AText>
					</div>
					<NuxtIcon name="20/close" filled @click="close" />
				</header>
				<div class="content">
					<template v-if="unreadNotifications.length || readNotifications.length">
						<div v-if="unreadNotifications.length" class="new">
							<AText class="text-cannes title" :modifiers="['bold']" as="div">{{ t("New") }}</AText>
							<ONotificationCenterMessage
								v-for="message in unreadNotifications"
								:key="`unread-${message.ncMessageId}`"
								:message="message"
								@click-on-message="handleClickNotificationMessage"
							/>
						</div>
						<div v-if="readNotifications.length" class="earlier">
							<AText class="text-cannes title" :modifiers="['bold']" as="div">{{ t("Earlier") }}</AText>
							<ONotificationCenterMessage
								v-for="message in readNotifications"
								:key="`read-${message.ncMessageId}`"
								:message="message"
								@click-on-message="handleClickNotificationMessage"
							/>
						</div>
					</template>
					<AText v-else class="text-caracas" :modifiers="['center']" as="div">{{
						t("Here will be your messages")
					}}</AText>
				</div>
				<footer>
					<AText class="text-caracas" variant="texas">{{
						t("Messages older than 14 days are deleted for you.")
					}}</AText>
					<AText variant="topeka" :modifiers="['link', 'underline']" @click="handleClickOpenSupport">
						{{ t("Customer Care") }}
					</AText>
				</footer>
			</div>
		</template>
	</APopper>
</template>

<style lang="scss" scoped>
.notification-center {
	&-icon {
		position: relative;
		.nuxt-icon {
			display: block;
			font-size: 24px;
			margin: 0;
			cursor: pointer;
			&.bell {
				transform-origin: top center;
				animation: bell 4s infinite;
			}
		}
		.badge {
			border-radius: 50%;
			position: absolute;
			z-index: 2;
			top: calc(50% - 15px);
			right: calc(50% - 15px);
		}
		@include media-breakpoint-down(lg) {
			padding: gutter(0.875) gutter(1.625);
			border-radius: 8px 0 0 8px;
			box-shadow: 0 0 12px var(--changchun);
			backdrop-filter: blur(5px);
		}
	}
	:deep(.popper) {
		--popper-theme-padding: 0;
		--popper-theme-background-color: var(--campina);
		--popper-theme-background-color-hover: var(--campina);
	}
	&-popper {
		width: 360px;
		header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: gutter(1.25) gutter(2);
			border-bottom: 1px solid var(--chaozhou);
			.count {
				color: var(--chaozhou);
				line-height: 21px;
				background: var(--cannes);
				border-radius: 4px;
				padding: gutter(0.25) gutter(1.125);
				margin-left: gutter(0.75);
			}
			.nuxt-icon {
				cursor: pointer;
				:deep(path) {
					fill: var(--cannes);
				}
			}
		}
		.content {
			overflow-y: auto;
			@include scrollbar(4px, gutter(0.5), var(--cabo), transparent, var(--cabo));
			& > div {
				padding: gutter(2);
			}
			.title {
				margin-bottom: gutter(2);
			}
			.new .message {
				cursor: pointer;
			}
			.earlier {
				background: var(--cocio);
			}
		}
		footer {
			background-color: var(--chaozhou);
			padding: gutter(1.5) gutter(2);
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include media-breakpoint-up(lg) {
				border-radius: 0 0 12px 12px;
			}
		}
	}
	&.in-menu {
		position: relative;
		padding: 0 gutter(3);
		@media (max-width: 1200px) {
			padding: 0 gutter(1);
		}
		@include media-breakpoint-down(lg) {
			display: none;
		}
		.content {
			max-height: 50vh;
		}
	}
	&.out-of-menu {
		.notification-center-icon {
			background: var(--a-header-background);
			-webkit-backdrop-filter: var(--a-header-backdrop-filter);
			backdrop-filter: var(--a-header-backdrop-filter);
		}
		:deep(.popper) {
			--popper-theme-border-width: 0;
			--popper-theme-border-radius: 0;
			--popper-theme-box-shadow: none;
			transform: none !important;
			max-width: 100vw;
			width: 100%;
			position: fixed !important;
			top: 64px !important;
			height: calc(100vh - 64px);
			z-index: 2001;

			@include media-breakpoint-down(lg) {
				top: var(--top-height) !important;
				height: calc(100vh - var(--top-height));
			}

			@media (orientation: landscape) {
				top: 0 !important;
				height: 100vh;
			}
		}
		.notification-center-popper {
			width: 100%;
			.content {
				height: calc(100dvh - 202px);

				@media (orientation: landscape) {
					top: 0 !important;
					height: calc(100dvh - 138px);
				}
			}
		}
	}
}

@keyframes bell {
	0% {
		transform: rotate(0deg);
	}
	80% {
		transform: rotate(0deg);
	}
	85% {
		transform: rotate(-10deg);
	}
	95% {
		transform: rotate(10deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
</style>

<style lang="scss">
#notification-center-container,
#notification-center-container-email {
	@include media-breakpoint-down(lg) {
		bottom: auto;
		left: auto;
		top: 64px;
	}

	.Toastify__toast-icon {
		width: 36px;
		margin-right: gutter(2);
		img {
			width: 36px;
			height: 36px;
		}
	}
	.Toastify__close-button {
		display: block;
	}
	.Toastify__toast {
		margin-bottom: gutter(1.5);
	}
	.Toastify__toast-body {
		max-width: 280px;
		font-size: 12px;
		line-height: 0;
		.notification-title {
			line-height: 1;
			margin: 0;
		}
		.notification-text {
			color: var(--caracas);
			margin: gutter(0.5) 0 0 0;
			line-height: 1.2;
		}
	}
}
</style>
